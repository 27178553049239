@tailwind base;
@tailwind components;
@tailwind utilities;

.login {
  background-image: var(--login-background-image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.no-org {
  /* background-image: url("../images/SadRobot.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.hamburger {
  cursor: pointer;
  width: 24px;
  transition: all 0.25s;
  position: relative;
}

.ficon {
  filter: invert(100%);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3;
  border-top: 10px solid #383636;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
